
header{
    transform:translateY(0);
    transition: 0.7s;
    
}
header.sticky{
    position:fixed;
    z-index:99;

    width:100%;
}
header.hide{
    transform:translateY(-130px);
  }
